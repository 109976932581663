import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
// import initReducer from './Store/initReducer';

export default {
  name: "Referral",
  dir: "Referral",
  pathRoot: "",
  routes: [
    {
      url: "apn/:code",
      component: "Page/Short",
      meta: {
        authRoute: true,
      },
      props: {
        title: "5 Phút Thuộc Bài",
        titleI18n: "Referral:title",
      },
    },
    {
      url: "tra-cuu",
      component: "Page/Search",
      meta: {
        authRoute: true,
      },
      props: {
        title: "5 Phút Thuộc Bài",
        titleI18n: "Referral:title",
      },
    },
    {
      url: "recheck",
      component: "Page/Recheck",
      meta: {
        authRoute: true,
      },
      props: {
        title: "5 Phút Thuộc Bài",
        titleI18n: "Referral:title",
      },
    },
    {
      url: "tra-cuu",
      component: "Page/Search",
      meta: {
        authRoute: true,
      },
      props: {
        title: "5 Phút Thuộc Bài",
        titleI18n: "Referral:title",
      },
    },
    {
      url: "nasa/:id",
      component: "Page/Nasa",
      meta: {
        authRoute: true,
      },
      props: {
        title: "5 Phút Thuộc Bài",
        titleI18n: "Referral:title",
      },
    },
    {
      url: "verify",
      component: "Page/Verify",
      meta: {
        authRoute: true,
      },
      props: {
        title: "5 Phút Thuộc Bài",
        titleI18n: "Referral:title",
      },
    },
    {
      url: "",
      component: "Page/index",
      meta: {
        authRoute: true,
      },
      props: {
        title: "5 Phút Thuộc Bài",
        titleI18n: "Referral:title",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: "Any",
  // redux: initReducer,
};
